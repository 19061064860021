import { withStyles } from 'tss-react/mui';
import { chunk } from "lodash";
import GreenButton from '../../../../shared_components/buttons/Green';

const styles = theme => ({
  contentTitle: {/* from parent component */},
  tagNumber: {/* from parent component */},
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagsColumn: {
    minWidth: '120px',
    marginRight: '50px',
    marginBottom: '20px',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0px'
    }
  },
  tagText: {
    marginLeft: '5px',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  btn: {
    maxHeight: '35px',
    margin: 'auto 0',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
});

const BodyTags = (props) => {
  const { classes, currentBodyTags, saveSoapNote, isUnsavedChanges, soap } = props;
  const notDeletedBodyTags = currentBodyTags.filter(({ status }) => status !== 'deleted');

  return (
    <>
      <div className={classes.contentWrapper}>
        <p className={classes.contentTitle}>Body Tags (click on the muscle to create a tag)</p>
        <GreenButton
          variant="contained"
          size="small"
          className={classes.btn}
          onClick={saveSoapNote}
          disabled={!isUnsavedChanges || !soap?.isChangeable}
        >
          Save
        </GreenButton>
      </div>
      <hr />
      <div className={classes.tags}>
        {chunk(notDeletedBodyTags, 7).map(group => (
          <div className={classes.tagsColumn} key={Math.random()}>
            {group.map(({ number, title }) => (
              <div className={classes.tag} key={number}>
                <div className={classes.tagNumber}>{number}</div>
                <div className={classes.tagText}>{title}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default withStyles(BodyTags, styles);
