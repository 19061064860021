import BaseSelect from './Base';
import TextField from '../../../../shared_components/Input/TextField';

const TextSelect = (props) => {
  const {
    id,
    name,
    className,
    classes,
    onChange,
    rootComponentProps,
    ...restProps
  } = props;

  return (
    <BaseSelect
      {...restProps}
      id={id}
      name={name}
      className={className}
      classes={classes}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...restProps} {...rootComponentProps} {...params} />
      )}
    />
  );
};

TextSelect.defaultProps = {
  rootComponentProps: {},
};

export default TextSelect;
