import { LockOpen as LockOpenIcon } from '@mui/icons-material';

import BaseIcon from './BaseIcon';

const UnlockButton = (props) => (
  <BaseIcon
    {...props}
    component={LockOpenIcon}
    iconProps={{
      color: 'primary',
      viewBox: '1 0 23 23',
    }}
  />
);

export default UnlockButton;
