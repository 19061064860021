import dynamic from 'next/dynamic';
import { Grid, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { getEditorText } from '../../utils/draftJsUtils';
import { emojiRegex } from '../../../../shared_components/stringUtils';

const Editor = dynamic(
  () => import('react-draft-wysiwyg').then(mod => mod.Editor),
  { ssr: false },
);

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: 'column',
  },
  editor: {
    height: '200px !important',
    overflowX: 'hidden !important',
    overflowY: 'scroll !important',
    padding: `${theme.spacing(0, 10 / 8)} !important`,
    border: '1px solid #f1f1f1 !important',
    '& *': {
      margin: '0 !important'
    },
    '& li': {
      marginLeft: '1rem !important'
    },
    '& span': {
      wordBreak: 'break-word'
    },
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  formHelperText: {
    color: 'red'
  },
}));

const defaultToolbar = {
  options: ['inline', 'list', 'textAlign', 'history'],
  inline: {
    options: ['bold', 'italic'],
  },
}

const SoapEditor = (props) => {
  const {
    editorState,
    onChange,
    readOnly,
    error,
    placeholderText = 'This is a text editor',
    customToolbar = defaultToolbar,
    customClasses = [],
  } = props;
  const {classes, cx} = useStyles();

  const handleChange = (state) => {
    const text = getEditorText(state);
    if (emojiRegex.test(text)) {
      return;
    }

    return onChange(state);
  }

  return (
    <Grid container className={classes.root}>
      <Editor
        readOnly={readOnly}
        editorState={editorState}
        onEditorStateChange={handleChange}
        style={{ border: "2px solid #000" }}
        wrapperClassName="wrapper-class"
        editorClassName={cx(
          classes.editor,
          error ? classes.error : '',
          ...customClasses
        )}
        placeholder={placeholderText}
        toolbar={customToolbar}
      />
      {error && (
        <FormHelperText className={classes.formHelperText}>
          {error}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default SoapEditor;
