import BaseInput from './Base';

const TextField = (props) => (
  <BaseInput
    {...props}
    size="medium"
    variant="standard"
  />
);

export default TextField;
