/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import LockButton from '../../../../shared_components/buttons/Lock';
import TrashButton from '../../../../shared_components/buttons/Trash';
import UnlockButton from '../../../../shared_components/buttons/Unlock';
import PrinterButton from '../../../../shared_components/buttons/Printer';
import Tooltip from '../Tooltip';
import CloseButton from '../../../../shared_components/buttons/Close';

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  lockedWord: {
    marginRight: theme.spacing(2),
    fontWeight: 500,
    borderBottom: '1px dashed',
  },
  dangerZone: {
    width: 'auto',
    marginRight: theme.spacing(),
    padding: theme.spacing(0, 1),
    borderLeft: `1px solid ${grey[400]}`,
    borderRight: `1px solid ${grey[400]}`,
    backgroundColor: theme.palette.error.light,
  },
}));

const HeaderExtraButtons = (props) => {
  const {
    isLocked,
    isChangeable,
    handleValidateSoap,
    handleOpenDeleteDialog,
    handleOpenUnlockDialog,
    auth: { currentStaff },
    onClickPrintPdf,
    onClose
  } = props;
  const {classes} = useStyles();

  if (isLocked) {
    return (
      <Grid container className={classes.root}>
        <Grid item className={classes.lockedWord}>
          <Tooltip title="Locked Chart cannot be edited">
            <i>Locked</i>
          </Tooltip>
        </Grid>
        {currentStaff.isOwner && (
          <Grid container className={classes.dangerZone}>
            <Grid item title="Delete Chart permanently">
              <TrashButton onClick={handleOpenDeleteDialog} />
            </Grid>
            <Grid item title="Unlock">
              <UnlockButton onClick={handleOpenUnlockDialog} />
            </Grid>
          </Grid>
        )}
        <Grid item title="Open PDF">
          <span
            onClick={onClickPrintPdf}
          >
            <PrinterButton />
          </span>
        </Grid>
        <Grid item>
          <CloseButton onClick={onClose} />
        </Grid>
      </Grid>
    );
  } else if (isChangeable) {
    return (
      <Grid container className={classes.root}>
        {currentStaff.isOwner && (
          <Grid container className={classes.dangerZone}>
            <Grid item title="Delete Chart permanently">
              <TrashButton onClick={handleOpenDeleteDialog} />
            </Grid>
          </Grid>
        )}
        <Grid item title="Lock">
          <LockButton onClick={handleValidateSoap} />
        </Grid>
        <Grid item>
          <CloseButton onClick={onClose} />
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default HeaderExtraButtons;
