/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { makeStyles } from 'tss-react/mui';
import {
  Hidden
} from '@mui/material';

import TagEditor from './TagEditor';
import { prepareOptions } from "../../../../shared_client_utils/formUtils";
import Select from '../Select';
import SelectBodyWithSwipe from './SelectBodyWithSwipe';

const useStyles = makeStyles()(() => ({
  typeSelectorBox: {
    width: '100%',
    margin: '0 auto 20px auto',
  },
  bodyBox: {
    position: 'relative',
    margin: '16px auto 0',
  },
  image: {
    maxHeight: '340px',
    maxWidth: '180px'
  },
  tagBox: {
    position: 'absolute',
  },
  tag: {/* from parent component */},
  tagNumber: {
    /* from parent component */
    cursor: 'pointer',
  }
}));

const HumanBody = (props) => {
  const {
    currentBodyTagType,
    handleSelectBodySide,
    bodyImageRef,
    onLoadBodyImage,
    onClickSetBodyTag,
    currentBodyTags,
    onClickOpenTagEditor,
    selectedBodyTagId,
    bodyTagTypes,
    bodySize,
    isTagEditorOpened,
    classes: parentClasses,
    ...restProps
  } = props;

  const {classes, cx} = useStyles();

  const bodyTagTypesOptions = prepareOptions(bodyTagTypes.byId);

  const currentSideBodyTags = currentBodyTags.filter(({ BodyTagTypeId }) => {
    return BodyTagTypeId === currentBodyTagType.value;
  });
  const notDeletedBodyTags = currentSideBodyTags.filter(({ status }) => {
    return status !== 'deleted';
  });

  const currentBodySize = currentBodyTagType ? bodySize[currentBodyTagType.value] : '';

  return (
    <>
      <Hidden mdDown>
        <div className={classes.typeSelectorBox}>
          <Select
            id="BodySideId"
            onChange={handleSelectBodySide}
            className={classes.dropDownSelect}
            options={bodyTagTypesOptions}
            value={currentBodyTagType}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        <SelectBodyWithSwipe
          handleTabChange={handleSelectBodySide}
          tabs={bodyTagTypesOptions}
          currentTab={currentBodyTagType}
        />
      </Hidden>
      <div className={classes.bodyBox} id="human-body-content">
        {currentBodyTagType && (
          <img
            ref={bodyImageRef}
            className={classes.image}
            src={`/images/body/${currentBodyTagType.label.toLowerCase()}.png`}
            alt={`${currentBodyTagType.label}`}
            onLoad={onLoadBodyImage}
            onClick={onClickSetBodyTag}
          />
        )}
        {currentBodySize && notDeletedBodyTags && notDeletedBodyTags.map((tag) => (
          <div
            key={tag.id}
            className={classes.tagBox}
            style={{
              top: tag.pointData.y,
              left: tag.pointData.x,
            }}
          >
            <div className={parentClasses.tag}>
              <div
                onClick={onClickOpenTagEditor(tag.id)}
                className={cx(parentClasses.tagNumber, classes.tagNumber)}
              >
                {tag.number}
              </div>
            </div>
            {(isTagEditorOpened && selectedBodyTagId === tag.id) && (
              <TagEditor
                {...restProps}
                tagId={tag.id}
                tagTitle={tag.title}
                status={tag.status}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default HumanBody;
