import { Lock as LockIcon } from '@mui/icons-material';

import BaseIcon from './BaseIcon';

const LockButton = (props) => (
  <BaseIcon
    {...props}
    component={LockIcon}
    iconProps={{
      color: 'primary',
      viewBox: '1 0 23 23',
    }}
  />
);

export default LockButton;
