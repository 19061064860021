import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = ({ shadows }) => ({
  canvas: {
    cursor: 'crosshair',
    touchAction: 'none',
    width: '100%',
    height: '100%',
  },
  blankCanvas: {
    display: 'none',
  },
  paperRoot: {
    display: 'flex',
    padding: '2px',
  },
});

const initialMousePosition = { x: 0, y:0 };

class ESignature extends Component {
  static propTypes = {
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired
  };

  blankCanvas = createRef();
  canvas = createRef();
  currentRequestID;
  mousePosition = initialMousePosition;
  lastPosition = initialMousePosition;
  drawing = false;

  componentDidMount() {
    this.drawLoop();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.currentRequestID);
  }

  getMousePosition = (canvas, { clientX, clientY }) => {
    const { left, top } = canvas.getBoundingClientRect();
    return { x: clientX - left, y: clientY - top };
  };

  getTouchPosition = (canvas, { touches }) => {
    const { clientX, clientY } = touches[0];
    const { left, top } = canvas.getBoundingClientRect();
    return { x: clientX - left, y: clientY - top };
  };

  handleMouseDown = event => {
    event.preventDefault && event.preventDefault();
    this.drawing = true;
    this.lastPosition = this.getMousePosition(event.target, event);
  };

  handleMouseMove = event => {
    event.preventDefault && event.preventDefault();
    this.mousePosition = this.getMousePosition(event.target, event);
  };

  handleMouseUp = event => {
    event.preventDefault && event.preventDefault();
    this.drawing = false;
  };

  handleTouchStart = event => {
    this.mousePosition = this.getTouchPosition(event.target, event);
    const { clientX, clientY } = event.touches[0];
    const me = new MouseEvent("mousedown", { clientX, clientY });
    this.drawing = true;
    this.lastPosition = this.getMousePosition(event.target, me);
  };

  handleTouchMove = event => {
    const { clientX, clientY } = event.touches[0];
    const me = new MouseEvent("mousemove", { clientX, clientY });
    this.mousePosition = this.getMousePosition(event.target, me);
  };

  handleTouchEnd = event => {
    event.preventDefault && event.preventDefault();
    this.drawing = false;
  };

  getCanvasContext = () => {
    const { current: canvas } = this.canvas;
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    const rect = canvas.getBoundingClientRect();
    context.fillRect(0, 0, rect.width, rect.height);
    context.strokeStyke = "#222222";
    context.lineWidth = 4;
    return context;
  };

  renderCanvas = () => {
    if (this.drawing) {
      const context = this.getCanvasContext();
      context.moveTo(this.lastPosition.x, this.lastPosition.y);
      context.lineTo(this.mousePosition.x, this.mousePosition.y);
      context.stroke();
      this.lastPosition = this.mousePosition;
    }
  };

  drawLoop = () => {
    this.currentRequestID = window.requestAnimationFrame(this.drawLoop);
    this.renderCanvas();
  };

  clearCanvas = () => {
    const { current: canvas } = this.canvas;
    canvas.width = canvas.width;
  };

  getDataUrl = () => {
    const { canvas: { current: canvas }, blankCanvas: { current: blankCanvas } } = this;
    const canvasData = canvas.toDataURL();
    return canvasData === blankCanvas.toDataURL() ? "" : canvasData;
  };

  render() {
    const { width, height, classes } = this.props;

    return (
      <Paper
        elevation={4}
        classes={{
          root: classes.paperRoot,
        }}
      >
        <canvas
          ref={this.canvas}
          width={width}
          height={height}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          className={classes.canvas}
        >
          Your browser does not support the canvas element, please update it.
        </canvas>
        <canvas ref={this.blankCanvas} width={width} height={height} className={classes.blankCanvas} />
      </Paper>
    );
  }
}

export default withStyles(ESignature, styles);
