import { Component } from "react";

const withMaxPageWidth = (width, overflow) => (Page) => (
  class MainPage extends Component {
    render() {
      return (
        <div
          style={{
            maxWidth: width,
            width: '100%',
            height: '100%',
            margin: '0 auto',
            ...(overflow && window?.innerWidth < 600 ? { overflow: 'auto' } : {}),
          }}
        >
          <Page {...this.props} />
        </div>
      );
    }
  }
);

export default withMaxPageWidth;
