/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Component } from 'react';
import { withStyles } from 'tss-react/mui';

import { tagRadius } from '../../utils/bodyTagUtils';
import Textarea from '../../../../shared_components/Textarea';

const styles = (theme) => ({
  tagEditor: {
    position: 'absolute',
    left: 0,
    top: tagRadius * 2 + 3,
    margin: '0 auto',
    padding: '10px 10px 4px 10px',
    backgroundColor: '#fff',
    boxShadow: "0px 0px 4px 0px #d8d8d8",
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '100',
    [theme.breakpoints.down('md')]: {
      left: '-6rem',
    },
  },
  textArea: {
    width: '180px',
    maxWidth: '300px',
    maxHeight: "200px",
    minWidth: '90px',
    minHeight: "70px",
  },
  tagEditorActions: {
    marginTop: '6px',
  },
  saveButton: {
    color: '#63DF98',
    cursor: 'pointer',
    float: 'right',
  },
  removeButton: {
    color: '#A6A7AF',
    cursor: 'pointer',
    float: 'left',
  },
  closeTag: {
    right: '-15px',
    top: '-18px',
    position: 'absolute',
    cursor: 'pointer',
    '&:focus': {
      outline: 0
    }
  },
  closeTagIcon: {
    width: "30px",
    height: "30px",
  },
});

class TagEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
    };
    this.onChangeTagText = this.onChangeTagText.bind(this);
  }

  componentDidMount() {
    const { tagId, tagTitle } = this.props;
    this.setState({
      id: tagId,
      title: tagTitle,
    })
  }

  onChangeTagText(event) {
    this.setState({ title: event.target.value });
  }

  render() {
    const {
      classes, onClickCloseTagEditor, onClickSaveTagText, onClickRemoveTag, status,
    } = this.props;
    const { id, title } = this.state;
    const closeAction = (status === 'initial') ? onClickRemoveTag(id) : onClickCloseTagEditor;

    return (
      <div className={classes.tagEditor}>
        <div className={classes.closeTag} >
          <img
            src="/close.svg"
            onClick={closeAction}
            alt="close tag"
            className={classes.closeTagIcon}
          />
        </div>
        <Textarea
          value={title}
          onChange={this.onChangeTagText}
          className={classes.textArea}
          minRows="5"
        />
        <div className={classes.tagEditorActions}>
          {(status !== 'initial') && (
            <s
              onClick={onClickRemoveTag(id)}
              className={classes.removeButton}
            >
              Remove
            </s>
          )}
          <a
            onClick={onClickSaveTagText(id, title)}
            className={classes.saveButton}
          >
            Save
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(TagEditor, styles);
