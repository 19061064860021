import { Component } from 'react';
import { withIdleTimer } from 'react-idle-timer';

class IdleTimer extends Component {
  render () {
    return this.props.children || [];
  }
}

export default withIdleTimer(IdleTimer);
