import { Visibility as VisibilityIcon } from '@mui/icons-material';

import BaseIcon from './BaseIcon';

const PreviewButton = (props) => (
  <BaseIcon
    {...props}
    component={VisibilityIcon}
    iconProps={{
      color: 'primary',
    }}
  />
);

export default PreviewButton;
