import { Fragment, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import OrangeButton from './Orange';
import { UploadIcon } from '../icons';

const useStyles = makeStyles()(theme => ({
  label: {
    width: "fit-content",
  },
  input: {
    display: "flex",
    width: 0,
    height: 0,
    overflow: "hidden",
    opacity: 0,
    position: "absolute",
    zIndex: "-1",
  },
  uploadButtonText: {
    marginRight: theme.spacing(),
    fontWeight: "400",
  },
  uploadIcon: {
    width: "20px",
    height: "20px",
  },
}));

const UploadButton = forwardRef((props, ref) => {
  const {
    id,
    accept,
    onChange,
    children,
    buttonComponent,
    className,
    buttonText,
    inputProps,
    buttonProps,
    alwaysReset,
  } = props;

  const {classes, cx} = useStyles();
  const inputRef = useRef(null);
  const handleChange = (event) => {
    onChange(event);

    if (alwaysReset) {
      inputRef.current.value = '';
    }
  };

  const ButtonComponent = buttonComponent || OrangeButton;

  return (
    <label
      htmlFor={id}
      className={cx(
        classes.label,
        className,
      )}
    >
      <input
        id={id}
        accept={accept}
        type="file"
        className={classes.input}
        onChange={handleChange}
        ref={inputRef}
        {...inputProps}
      />
      <ButtonComponent
        variant="contained"
        size="medium"
        {...buttonProps}
        component="div"
        ref={ref}
      >
        {children || (
          <Fragment>
            <span className={classes.uploadButtonText}>
              {buttonText}
            </span>
            <UploadIcon className={classes.uploadIcon} />
          </Fragment>
        )}
      </ButtonComponent>
    </label>
  );
});

UploadButton.defaultProps = {
  className: '',
  buttonText: 'Upload',
  id: 'uploadButton',
  accept: 'image/*',
  inputProps: {},
  buttonProps: {},
  alwaysReset: false,
};

UploadButton.propTypes = {
  onChange: PropTypes.func,
};

export default UploadButton;
