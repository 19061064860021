export const keepReferrerInBrowser = (referrer) => {
  try {
    return sessionStorage.setItem('returnTo', referrer);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAndClearReferrerFromBrowser = () => {
  let referrer = null;
  try {
    referrer = sessionStorage.getItem('returnTo');
  } catch (error) {
    console.log(error);
    return null;
  }

  try {
    sessionStorage.removeItem('returnTo');
  } catch (error) {
    console.log(error)
    return referrer;
  }

  return referrer;
};
