import { useState, useEffect } from 'react';
import { Grid, Paper, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import TrashButton from '../../../shared_components/buttons/Trash';
import PreviewButton from '../../../shared_components/buttons/Preview';
import ConfirmDeletionPopup from './ConfirmDeletionPopup';
import InputWithButtons from './InputWithButtons';

const useStyles = makeStyles()(theme => ({
  attachmentRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 120px',
    gap: '20px',
    position: 'relative',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  fileLinks: {
    justifyContent: 'flex-end',
  },
  inputRoot: {
    flex: 2,
  },
  trashButton: {
    marginLeft: theme.spacing(1/2),
  },
  backdrop: {
    position: 'absolute !important',   // parent's styles override current ones
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
  },
  progressModal: {
    position: 'absolute !important',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 'none',
    background: 'transparent',
    boxShadow: 'none',
  },
}));

const AttachmentRow = (props) => {
  const {
    id,
    name,
    addedTime,
    previewAction,
    removeAction,
    onChange,
    onCancel,
    onSave,
    editable,
    displayCancel,
    displaySave,
    disableButtons,
    classes: parentClasses,
  } = props;

  const {classes, cx} = useStyles();
  parentClasses['root'] = parentClasses['root'] || '';
  parentClasses['fileLinks'] = parentClasses['fileLinks'] || '';

  const [isConfirmPopupOpened, setConfirmPopupOpen] = useState(false);
  const [deleteButtonAnchor, setDeleteButtonAnchor] = useState(null);

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    return () => setLoading(false);
  }, []);

  const onClickConfirmPopupOpen = (event) => {
    setConfirmPopupOpen(true);
    setDeleteButtonAnchor(event.currentTarget);
  }

  const onClickSaveButton = () => {
    setLoading(true);
    onSave(() => setLoading(false));
  }

  const onClickDeleteButton = () => {
    setLoading(true);
    removeAction();
  }

  return (
    <div className={cx(
      classes.attachmentRow,
      parentClasses.root,
    )}>
      <div>{addedTime}</div>
      <Grid container>
        {editable ? (
          <InputWithButtons
            id={`name-${id}`}
            name="name"
            value={name}
            onChange={onChange}
            className={classes.inputRoot}
            displaySave={displaySave}
            displayCancel={displayCancel}
            onSaveButtonProps={{
              onClick: onClickSaveButton,
              disabled: disableButtons,
            }}
            onCancelButtonProps={{
              onClick: onCancel,
            }}
          />
        ) : (
          name
        )}
      </Grid>

      <Grid
        container
        className={cx(
          classes.fileLinks,
          parentClasses.fileLinks,
        )}
      >
        <PreviewButton onClick={previewAction} />
        {editable && (
          <TrashButton
            onClick={onClickConfirmPopupOpen}
            disabled={disableButtons}
            className={classes.trashButton}
          />
        )}
      </Grid>

      <ConfirmDeletionPopup
        open={isConfirmPopupOpened}
        anchorEl={deleteButtonAnchor}
        onClickAway={() => setConfirmPopupOpen(false)}
        placement="bottom-end"
        onCancel={() => setConfirmPopupOpen(false)}
        onDelete={onClickDeleteButton}
      />

      {isLoading && (
        <div className={classes.backdrop}>
          <Paper className={classes.progressModal}>
            <CircularProgress />
          </Paper>
        </div>
      )}
    </div>
  );
};

AttachmentRow.defaultProps = {
  editable: true,
  displayCancel: false,
  displaySave: false,
  disableButtons: false,
  classes: {},
};

export default AttachmentRow;
