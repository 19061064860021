import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export const initializeEditorState = (text) => {
  if (!text) {
    return EditorState.createEmpty();
  }

  const raw = JSON.parse(text);
  const contentState = convertFromRaw(raw);
  return EditorState.createWithContent(contentState);
};

export const convertStateToString = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const raw = convertToRaw(contentState);
  return JSON.stringify(raw);
};

export const getEditorText = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return contentState.getPlainText();
};

export const getEditorHTML = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return stateToHTML(contentState);
};

export const getEditorHTMLwithOptions = (editorState, options = {}) => {
  const contentState = editorState.getCurrentContent();
  return stateToHTML(contentState, options);
};
