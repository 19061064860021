/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unescaped-entities */
import { Component, createRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import dynamic from 'next/dynamic';
import {
  Grid,
  Dialog,
  FormHelperText,
  Typography,
  Tabs,
  Tab,
  Hidden,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { cx } from '@emotion/css'
import { withStyles } from 'tss-react/mui';
import { ArrowForwardIos as ArrowForwardIcon } from '@mui/icons-material';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';

import SignatureDialog from '../SignatureDialog';
import HeaderExtraButtons from './HeaderExtraButtons';
import BodyTags from './BodyTags';
import HumanBody from './HumanBody';
import SoapEditor from './SoapEditor';
import DeleteSoapNote from './DeleteSoapNote';
import AttachmentRow from '../AttachmentRow';
import {
  prepareOptions,
  validateFile,
  makeOption,
  validateObjectByMap,
  createOptionsOfNumbers,
  makeOptionFromArray,
} from "../../../../shared_client_utils/formUtils";
import {
  initializeEditorState,
  convertStateToString,
  getEditorText,
  getEditorHTML,
} from "../../utils/draftJsUtils";
import { dataURIToBlob } from "../../utils";
import { getExtension } from '../../utils/fileUtils';
import { timezoneizeObjects } from '../../../../shared_client_utils/dateUtils';
import { formatFileDatetime } from '../../utils/clientFileUtils';
import {
  tagRadius,
  calculateNextBodyTagsNumber,
  calculatePoint,
} from '../../utils/bodyTagUtils';
import Select from '../Select';
import SelectWithoutSearch from '../Select/TextField';
import { loadBodyTagTypes } from '../../slices/bodyTagTypesSlice';
import { loadPrimaryReasons } from '../../slices/primaryReasonsSlice';
import { loadBodyTags } from '../../slices/bodyTagsSlice';
import {
  createSoapFile,
  updateSoapFile,
  deleteSoapFile,
  loadSoapFiles
} from '../../slices/soapFilesSlice';
import { SoapNotesApi } from '../../../../client_http_api';
import { ClipIcon } from "../../../../shared_components/icons";
import { OrangeInfoStrip } from '../../../../shared_components/InfoStrip';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';
import { composeGCSUrl } from '../../../../shared_client_utils/googleCloudStorageUtils';
import CustomDialog, {
  CustomDialogContent,
} from '../../../../shared_components/CustomDialog';
import UploadButton from '../../../../shared_components/buttons/Upload';
import ConfirmDialog from '../ConfirmDialog';
import followUpTimePeriods from '../../configs/followUpTimePeriods';
import SelectTabWithSwipe from './SelectTabWithSwipe';

const PreviewFile = dynamic(() => import('../PreviewFile'), { ssr: false });

const styles = (theme, _, classes) => ({
  noChangeReasonBox: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  main: {
    flexDirection: 'column',
    minHeight: 'calc(100vh - 8rem)',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '25% 1fr',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      gap: '8px'
    },
  },
  leftContainer: {
    flexDirection: 'column',
    height: '100%',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  content: {
    // marginTop: theme.spacing(2),
  },
  tabItem: {
    fontWeight: 600
  },
  dropDownSelect: {
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '275px',
    },
  },
  enteredContent: {
    flexDirection: 'column',
    width: '100%',

    '& .wrapper-class': {
      wordBreak: 'break-all',
      '& .editor-class': {
        '& .DraftEditor-root': {
          '& .DraftEditor-editorContainer': {
            wordBreak: 'break-all',
          }
        },
      },
    },
  },
  selectionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  reasonBox: {
    flexDirection: 'column',
    width: 'auto',
    marginRight: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  serviceBox: {
    flexDirection: 'column',
    width: 'auto',
  },
  contentTitle: {
    fontSize: '20px',
    margin: theme.spacing(1, 0),
    color: "#808190",
    fontWeight: 'normal',
    fontFamily: 'Roboto-Regular',

    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
  bodyTagTitle: {
    fontSize: '20px',
    margin: theme.spacing(1, 0),
    color: "#808190",
    fontWeight: 'normal',
    fontFamily: 'Roboto-Regular',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  bodyParentTagTitle: {
    fontSize: '20px',
    margin: theme.spacing(1, 0),
    color: "#808190",
    fontWeight: 'normal',
    fontFamily: 'Roboto-Regular',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  soapFiles: {
    flexDirection: 'column',
  },
  soapFile: {
    marginBottom: theme.spacing(),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  contentServiceTitle: {
    fontSize: theme.spacing(2),
    margin: theme.spacing(10 / 8, 0),
    color: "#808190",
    fontWeight: 'normal',
    fontFamily: 'Roboto-Regular',
  },
  bodyTagsBox: {
    marginTop: theme.spacing(2),
  },
  tag: {
    position: 'absolute',
  },
  tagNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: tagRadius * 2,
    height: tagRadius * 2,
    fontSize: '15px',
    border: '1px solid #f3980d',
    borderRadius: '50%',
    color: '#f3980d',
    backgroundColor: '#fbddb0',
  },
  tagText: {
    fontWeight: 500,
  },
  buttonBox: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  saveButton: {
    color: 'white',
    fontSize: "18px",
    cursor: "pointer",
    padding: '7px 38px',
    borderRadius: "22px",
    border: "1px solid #bfbdbd",
    boxShadow: '0px 0px 15px 8px #fafafa',
    backgroundColor: theme.palette.secondary.main,
  },
  filePreviewPaper: {
    width: '100%',
    height: '100%',
    boxShadow: "none",
    backgroundColor: 'transparent',
    overflowY: 'initial',
    margin: '63px',
    maxWidth: 'fit-content',
  },
  uploadButtonBox: {
    width: '170px',
    height: '50px',
    marginTop: '20px',
  },
  formHelperText: {
    color: 'red'
  },
  tabsRoot: {
    border: "0.4px solid #eee",
    background: "#fcfbfc",
    borderRadius: 5,
    width: '100%',
  },
  flexContainer: {
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  tabsIndicator: {
    backgroundColor: 'unset',
  },
  tabRoot: {
    fontSize: 16,
    textTransform: 'initial',
    minWidth: 72,
    padding: "6px 12px",
    maxHeight: '36px',
    [`&.${classes.tabSelected}`]: {
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
      borderRadius: 5,
      boxShadow: "0px 5px 9px 1px #f5a62329",
      fontWeight: 600,
      '& g > path': {
        fill: theme.palette.primary.main,
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 500,
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      minWidth: 0,
    }
  },
  tabSelected: {
    margin: '5px',
  },
  followUpBox: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '14px',
    }
  },
  followUpLeftSelect: {
    marginLeft: theme.spacing(),
    width: '8%',
    [theme.breakpoints.down('md')]: {
      width: '16%',
      fontSize: '14px',
    }
  },
  followUpRightSelect: {
    marginLeft: theme.spacing(),
    width: '12%',
    [theme.breakpoints.down('md')]: {
      width: '26%',
      fontSize: '14px',
    }
  },
  dateNavigatorWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '.8rem',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      paddingTop: 0,
    },
  },
  dateNavigatorContent: {
    margin: 'auto 0',
    minWidth: '7.5rem',
  },
  currentIndex: {
    marginLeft: 'auto',
    marginTop: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      textAlign: 'center',
      fontSize: '14px',
    },
  },
  arrowBox: {
    padding: theme.spacing(),
    width: 'auto',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.7',
    },
  },
  disabledArrowBox: {
    opacity: '0.3',
    cursor: 'inherit',
    '&:hover': {
      cursor: 'inherit',
      opacity: '0.3',
    }
  },
  leftArrowIcon: {
    transform: 'rotate(180deg)',
  },
  arrowIcon: {
    color: grey[700],
  },
  dateNavigatorTitle: {
    fontFamily: "SF Pro Display, Roboto",
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#000000',
    padding: '0 0 8px 6px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0 .5rem 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      padding: '.5rem',
    },
  },
  headerTitle: {
    display: 'flex',
    color: '#4C4C4C',
    fontFamily: 'Roboto',
    fontSize: theme.spacing(5 / 2),
    fontWeight: 500,
    width: 'fit-content',
    paddingBottom: theme.spacing(1 / 2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  extraButtons: {
    width: '100%',
    display: 'flex',
  },
  titleWrapper: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  btn: {
    maxHeight: '35px',
    margin: 'auto 0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      width: '50%'
    },
  },
  soapWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: 0
    },
  },
  reasonDropDown: {
    marginBottom: theme.spacing(2),
  }
});

const prepareSoapData = (options) => {
  const {
    businessId,
    newFiles,
    savedFiles,
    currentBodyTags,
    dataUrl,
    soap: {
      subjective,
      objective,
      assessment,
      plan,
      homeCare,
      ...soap
    },
  } = options;

  const data = new FormData();
  data.append('BusinessId', businessId);
  data.append('subjective', convertStateToString(subjective));
  data.append('objective', convertStateToString(objective));
  data.append('assessment', convertStateToString(assessment));
  data.append('plan', convertStateToString(plan));
  data.append('homeCare', convertStateToString(homeCare));
  data.append('homeCareHTML', getEditorHTML(homeCare));

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const prop in soap) {
    const value = (soap[prop] === null) ? '' : soap[prop];
    data.append(prop, value);
  }

  const bodyTags = currentBodyTags.filter(({ status }) => status !== 'initial');
  data.append('bodyTags', JSON.stringify(bodyTags));

  data.append('files', JSON.stringify(savedFiles));

  for (let i = 0; i < newFiles.length; i += 1) {
    const { file } = newFiles[i];
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], newFiles[i].id, { type: file.type });
    data.append('file', newFile);
  }
  const files = newFiles.map(({ id, name, file }) => ({
    id,
    name,
    originalName: file.name,
  }));
  data.append('newFiles', JSON.stringify(files));

  if (dataUrl) {
    data.append("signature", dataURIToBlob(dataUrl, "image/png"), "signature.png");
  }

  return data;
};

const prepareNewFile = (file, soapNoteId, appointmentId, clientId, businessId) => {
  const data = new FormData();
  data.append('id', file.id);
  data.append('name', file.name);
  data.append('soapNoteId', soapNoteId);
  data.append('appointmentId', appointmentId);
  data.append('clientId', clientId);
  data.append('businessId', businessId);
  data.append('file', file.file);
  return data;
};

const prepareSavedFile = (file, soapNoteId, appointmentId) => ({
  soapNoteId,
  appointmentId,
  id: file.id,
  name: file.name,
})

const tabs = [
  { label: 'Reason for visit', value: 'reason' },
  { label: 'Subjective', value: 'subjective' },
  { label: 'Objective', value: 'objective' },
  { label: 'Assessment', value: 'assessment' },
  { label: 'Plan', value: 'plan' },
  { label: 'Home Care', value: 'homeCare' },
  {
    label: (
      <ClipIcon
        style={{
          width: 16,
          height: 16,
          transform: 'rotate(-18deg)',
        }}
      />
    ),
    value: 'notes',
  },
];

const mobileTabs = [
  { label: 'Reason for visit', value: 'reason' },
  { label: 'Subjective', value: 'subjective' },
  { label: 'Objective', value: 'objective' },
  { label: 'Assessment', value: 'assessment' },
  { label: 'Plan', value: 'plan' },
  { label: 'Home Care', value: 'homeCare' },
  { label: 'Attachments', value: 'notes' },
];

const followUpTimeValueOptions = createOptionsOfNumbers(10);
const followUpTimePeriodOptions = Object.values(followUpTimePeriods)
  .map(({ name, period }) => makeOptionFromArray([period, name]));

const tabComponents = {
  reason: (props) => {
    const {
      classes,
      soap,
      handleSelectOption,
      primaryReasonsOptions,
      currentPrimaryReason,
      errors,
    } = props;

    return (
      <Grid container className={classes.selectionWrap}>
        <Grid container className={classes.reasonBox}>
          <Hidden mdDown>
            <p className={classes.contentTitle}>What's the primary reason for visit?</p>
            <Select
              id="PrimaryReasonId"
              disabled={!soap.isChangeable}
              onChange={(value) => handleSelectOption(value, 'PrimaryReasonId')}
              className={classes.dropDownSelect}
              options={primaryReasonsOptions}
              value={currentPrimaryReason}
              error={errors.PrimaryReasonId}
            />
          </Hidden>
          <Hidden mdUp>
            {/* <p className={classes.contentTitle} /> */}
            <SelectWithoutSearch
              id="PrimaryReasonId"
              label="Primary reason for visit"
              disabled={!soap.isChangeable}
              onChange={(value) => handleSelectOption(value, 'PrimaryReasonId')}
              className={classes.dropDownSelect}
              options={primaryReasonsOptions}
              value={currentPrimaryReason}
              error={errors.PrimaryReasonId}
              blurOnSelect
            />
          </Hidden>
          {errors.PrimaryReasonId && (
            <FormHelperText className={classes.formHelperText}>
              {errors.PrimaryReasonId}
            </FormHelperText>
          )}
        </Grid>

        <Grid container className={classes.serviceBox}>
          <div className={classes.serviceContent}>
            <p className={classes.contentServiceTitle}>Service category</p>
            <div>{soap.serviceCategoryName}</div>
          </div>
          <div className={classes.serviceContent}>
            <p className={classes.contentServiceTitle}>Service provided</p>
            <div>{soap.serviceName}</div>
          </div>
        </Grid>
      </Grid>
    );
  },
  subjective: ({ classes, soap, handleChangeEditor }) => (
    <Grid container className={classes.enteredContent}>
      <p className={classes.contentTitle}>
        Information provided by the client:
      </p>

      <SoapEditor
        editorState={soap.subjective}
        onChange={handleChangeEditor('subjective')}
        readOnly={!soap.isChangeable}
      />
    </Grid>
  ),
  objective: ({ classes, soap, handleChangeEditor }) => (
    <Grid container className={classes.enteredContent}>
      <p className={classes.contentTitle}>
        Physical examination and test results:
      </p>

      <SoapEditor
        editorState={soap.objective}
        onChange={handleChangeEditor('objective')}
        readOnly={!soap.isChangeable}
      />
    </Grid>
  ),
  assessment: ({ classes, soap, handleChangeEditor }) => (
    <div className={classes.enteredContent}>
      <p className={classes.contentTitle}>
        Summary and conclusion from examination:
      </p>

      <SoapEditor
        editorState={soap.assessment}
        onChange={handleChangeEditor('assessment')}
        readOnly={!soap.isChangeable}
      />
    </div>
  ),
  plan: ({ classes, soap, handleChangeEditor }) => (
    <Grid container className={classes.enteredContent}>
      <p className={classes.contentTitle}>Treatment plan:</p>

      <SoapEditor
        editorState={soap.plan}
        onChange={handleChangeEditor('plan')}
        readOnly={!soap.isChangeable}
      />
    </Grid>
  ),
  homeCare: (props) => {
    const {
      classes,
      soap,
      handleChangeEditor,
      handleSelectOption,
      errors,
    } = props;

    const selectedFollowUpTimeValue = followUpTimeValueOptions
      .find(({ value }) => {
        return value === soap.followUpTimeValue;
      });
    const selectedFollowUpTimePeriod = followUpTimePeriodOptions
      .find(({ value }) => {
        return value === soap.followUpTimePeriod;
      });

    return (
      <Grid container className={classes.enteredContent}>
        <p className={classes.contentTitle}>Home Care:</p>

        <SoapEditor
          editorState={soap.homeCare}
          placeholderText="Type your personalized home care plan for the client here and select the recommended return date. This will be emailed directly to them once your note is locked."
          readOnly={!soap.isChangeable}
          onChange={handleChangeEditor('homeCare')}
          error={errors.homeCare}
        />

        <Grid container className={classes.followUpBox}>
          <Grid item>Recommended return date </Grid>
          <Select
            id="followUpTimeValue"
            disabled={!soap.isChangeable}
            onChange={(option) => handleSelectOption(option, 'followUpTimeValue')}
            className={classes.followUpLeftSelect}
            options={followUpTimeValueOptions}
            value={selectedFollowUpTimeValue}
          />
          <Select
            id="followUpTimePeriod"
            disabled={!soap.isChangeable}
            onChange={(option) => handleSelectOption(option, 'followUpTimePeriod')}
            className={classes.followUpRightSelect}
            options={followUpTimePeriodOptions}
            value={selectedFollowUpTimePeriod}
          />
        </Grid>
      </Grid>
    );
  },
  notes: (props) => {
    const {
      soap,
      classes,
      newFiles,
      savedFiles,
      attachmentLoading,
      onClickShowFilePreview,
      onClickRemoveNewFile,
      onChangeNewFile,
      onCreateFile,
      onClickRemoveSavedFile,
      onChangeSavedFile,
      onCancelFileChanges,
      onSaveFile,
      onChangeFilesField,
      business: { timezone },
    } = props;

    const soapFiles = timezoneizeObjects(savedFiles, timezone, 'createdAt');

    return (
      <Grid container className={classes.enteredContent}>
        <p className={classes.contentTitle}>Attachments</p>

        <Grid container className={classes.soapFiles}>
          {newFiles.map(({ id, name, preview, file }) => (
            <Grid item className={classes.soapFile}>
              <AttachmentRow
                displaySave
                key={id}
                id={id}
                name={name}
                addedTime="New file"
                disableButtons={attachmentLoading}
                previewAction={onClickShowFilePreview(preview, file.name)}
                removeAction={onClickRemoveNewFile(id)}
                onChange={onChangeNewFile(id)}
                onSave={onCreateFile(id)}
              />
            </Grid>
          ))}

          {soapFiles.map(({ id, name, createdAtTz, file, changed }) => (
            <Grid item className={classes.soapFile}>
              <AttachmentRow
                key={`${id}-${attachmentLoading}`}
                id={id}
                name={name}
                addedTime={formatFileDatetime(createdAtTz)}
                disableButtons={attachmentLoading}
                previewAction={onClickShowFilePreview(composeGCSUrl(file), file)}
                removeAction={onClickRemoveSavedFile(id)}
                onChange={onChangeSavedFile(id)}
                editable={soap.isChangeable}
                displayCancel={changed}
                onCancel={onCancelFileChanges(id)}
                displaySave={changed}
                onSave={onSaveFile(id)}
              />
            </Grid>
          ))}
        </Grid>

        {soap.isChangeable && (
          <div className={classes.uploadButtonBox}>
            <UploadButton
              id="soapFile"
              onChange={onChangeFilesField}
              buttonText="Upload file"
              inputProps={{
                multiple: true,
                accept: 'image/* , .pdf, .doc',
              }}
            />
          </div>
        )}
      </Grid>
    );
  },
};

const homeCareTextLimit = 25;
const saveSoapNoteErrorsMap = {
  PrimaryReasonId: {
    isValid: (value) => !!value,
    message: 'Please select primary reason to visit',
  },
};
const lockSoapNoteErrorsMap = {
  ...saveSoapNoteErrorsMap,
  homeCare: {
    isValid: (editorState) => {
      if (!editorState) { return }

      const text = getEditorText(editorState);
      return text.length >= homeCareTextLimit;
    },
    message: `must be greater than ${homeCareTextLimit} symbols`,
  },
};
const getInitialState = () => ({
  soap: {
    subjective: initializeEditorState(),
    objective: initializeEditorState(),
    assessment: initializeEditorState(),
    plan: initializeEditorState(),
    homeCare: initializeEditorState(),
    followUpTimeValue: 1,
    followUpTimePeriod: followUpTimePeriods.day.period,
  },
  currentTab: 'reason',
  bodySize: {},
  currentBodyTags: [],
  selectedBodyTagId: null,
  isTagEditorOpened: false,
  savedFiles: [],
  newFiles: [],
  isLoading: true,
  isFilePreviewDialogOpened: false,
  currentFile: {},
  backBodyImage: '',
  frontBodyImage: '',
  errors: {},
  pdfUrl: '',
  isUnsavedChanges: false,
  attachmentLoading: false,
  isSignatureDialogOpened: false,
  isSignatureDialogLoading: false,
  isSignatureEmpty: false,
  isDeleteDialogOpened: false,
  isUnlockDialogOpened: false,
  currentAppointment: {},
  sortedClientAppointments: [],
  leftArrowDisabled: false,
  rightArrowDisabled: false,
  currentIndex: 0,
})
let printTimeoutid;
let saveTimeoutId;
class SoapNote extends Component {
  constructor(props) {
    super(props)
    this.state = getInitialState();
    this.bodyImageRef = createRef();
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleSelectBodySide = this.handleSelectBodySide.bind(this);
    this.onClickLockSoapNote = this.onClickLockSoapNote.bind(this);
    this.onLoadBodyImage = this.onLoadBodyImage.bind(this);
    this.onClickSetBodyTag = this.onClickSetBodyTag.bind(this);
    this.onClickOpenTagEditor = this.onClickOpenTagEditor.bind(this);
    this.onClickCloseTagEditor = this.onClickCloseTagEditor.bind(this);
    this.onClickSaveTagText = this.onClickSaveTagText.bind(this);
    this.onClickRemoveTag = this.onClickRemoveTag.bind(this);
    this.onClickSaveSoapNote = this.onClickSaveSoapNote.bind(this);
    this.saveSoapNote = this.saveSoapNote.bind(this);
    this.onChangeFilesField = this.onChangeFilesField.bind(this);
    this.onClickShowFilePreview = this.onClickShowFilePreview.bind(this);
    this.onCloseFilePreviewDialog = this.onCloseFilePreviewDialog.bind(this);
    this.onClickRemoveNewFile = this.onClickRemoveNewFile.bind(this);
    this.onChangeSavedFile = this.onChangeSavedFile.bind(this);
    this.onChangeNewFile = this.onChangeNewFile.bind(this);
    this.onClickRemoveSavedFile = this.onClickRemoveSavedFile.bind(this);
    this.handleValidateSoap = this.handleValidateSoap.bind(this);
    this.onClickOpenSignatureDialog = this.onClickOpenSignatureDialog.bind(this);
    this.onClickCloseSignatureDialog = this.onClickCloseSignatureDialog.bind(this);
    this.onCreateFile = this.onCreateFile.bind(this);
    this.onSaveFile = this.onSaveFile.bind(this);
    this.onCancelFileChanges = this.onCancelFileChanges.bind(this);
    this.addUnsavedChanges = this.addUnsavedChanges.bind(this);
    this.saveUnsavedChanges = this.saveUnsavedChanges.bind(this);
    this.handleOpenDeleteDialog = this.handleOpenDeleteDialog.bind(this);
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
    this.handleDeleteSOAP = this.handleDeleteSOAP.bind(this);
    this.handleOpenUnlockDialog = this.handleOpenUnlockDialog.bind(this);
    this.handleCloseUnlockDialog = this.handleCloseUnlockDialog.bind(this);
    this.handleUnlockSOAP = this.handleUnlockSOAP.bind(this);
    this.onSaveOrRemoveTag = this.onSaveOrRemoveTag.bind(this);
    this.goToBack = this.goToBack.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.getNewData = this.getNewData.bind(this);
    this.delayedSaving = this.delayedSaving.bind(this);

    props.captureBeforeInactiveAction('soap', this.saveSoapNote);
  }

  async componentDidMount() {
    await this.getNewData(this.props.selectedAppointmentId, true);
  }

  delayedSaving() {
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }
    saveTimeoutId = setTimeout(() => {
      this.saveSoapNote();
    }, 30000);
  }

  addUnsavedChanges() {
    this.delayedSaving()
    this.setState({ isUnsavedChanges: true });
  }

  saveUnsavedChanges() {
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }
    this.setState({ isUnsavedChanges: false });
  }

  handleSelectOption = (option, name) => {
    const { errors, soap: originalSoap } = this.state;

    const newValue = option ? option.value : '';
    const soap = { ...originalSoap, [name]: newValue };
    if (newValue && errors[name]) {
      delete errors[name];
    }

    this.setState({ soap, errors }, this.addUnsavedChanges);
  };

  handleSelectBodySide = (option) => {
    this.setState({ currentBodyTagType: option });
  };

  handleChangeEditor = (item) => (editorState) => {
    const { handleDisplayFlashMessage } = this.props;
    const stringRaw = convertStateToString(editorState);
    if (stringRaw.length >= 65535) {
      handleDisplayFlashMessage(`Data too long for ${item}`, 'error');
      return;
    }

    this.setState(({ soap }) => ({
      soap: {
        ...soap,
        [item]: editorState,
      },
    }), this.addUnsavedChanges);
  };

  handleValidateSoap() {
    const { soap } = this.state;
    const { handleDisplayFlashMessage } = this.props;
    const { isValid, errors } = validateObjectByMap(soap, lockSoapNoteErrorsMap);
    if (!isValid) {
      this.setState({ errors });
      if (errors.homeCare) {
        handleDisplayFlashMessage('Please fill in Home Care description', 'error');
        this.setState({ currentTab: 'homeCare' });
        return;
      }
      if (errors.PrimaryReasonId) {
        handleDisplayFlashMessage('Please select primary reason to visit', 'error');
        this.setState({ currentTab: 'reason' });
        return;
      }
    }
    this.onClickOpenSignatureDialog();
  }

  handleOpenUnlockDialog() {
    this.setState({
      isUnlockDialogOpened: true,
    });
  }

  handleCloseUnlockDialog() {
    this.setState({
      isUnlockDialogOpened: false,
    });
  }

  async handleUnlockSOAP() {
    const {
      soap: {
        id,
        AppointmentId: appointmentId,
      },
    } = this.state;
    const { auth, onExitCallback, handleDisplayFlashMessage, removeBeforeInactiveAction, handleCloseSOAPNoteDialog, callbackOptions = {} } = this.props;
    const { currentStaff } = auth;

    this.setState({
      isLoading: true,
      isUnlockDialogOpened: false,
    });

    try {
      const options = {
        appointmentId,
        currentStaffId: currentStaff.id,
      };
      await SoapNotesApi.unlockSOAPNote(id, options, auth);

      onExitCallback(callbackOptions);
      handleDisplayFlashMessage(
        'Chart has been unlocked successfully',
      );
      removeBeforeInactiveAction('soap');
      handleCloseSOAPNoteDialog();
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(err?.message || 'Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
    }
  }

  handleOpenDeleteDialog() {
    this.setState({
      isDeleteDialogOpened: true,
    });
  }

  handleCloseDeleteDialog() {
    this.setState({
      isDeleteDialogOpened: false,
    });
  }

  async handleDeleteSOAP() {
    const {
      soap: {
        id,
        AppointmentId: appointmentId,
      },
    } = this.state;
    const { auth, onExitCallback, handleDisplayFlashMessage, removeBeforeInactiveAction, handleCloseSOAPNoteDialog, callbackOptions = {} } = this.props;
    const { currentStaff } = auth;

    this.setState({
      isLoading: true,
      isDeleteDialogOpened: false,
    });

    try {
      const options = {
        appointmentId,
        currentStaffId: currentStaff.id,
      };
      await SoapNotesApi.deleteSOAPNote(id, options, auth);
      if (this.saveUnsavedChanges) {
        this.saveUnsavedChanges()
      }
      onExitCallback(callbackOptions);
      handleDisplayFlashMessage(
        'Chart has been deleted successfully',
      );
      removeBeforeInactiveAction('soap');
      handleCloseSOAPNoteDialog();
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(err?.message || 'Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
    }
  }

  async onClickSaveSoapNote() {
    const { soap, isUnsavedChanges } = this.state;
    const { removeBeforeInactiveAction, handleCloseSOAPNoteDialog, onExitCallback, handleDisplayFlashMessage, callbackOptions = {} } = this.props;
    if (!isUnsavedChanges) {
      removeBeforeInactiveAction('soap');
      handleCloseSOAPNoteDialog();
      return;
    }

    const { isValid, errors } = validateObjectByMap(soap, saveSoapNoteErrorsMap);
    if (!isValid) {
      if (errors.PrimaryReasonId) {
        handleDisplayFlashMessage('Please select primary reason to visit', 'error');
        this.setState({ errors, currentTab: 'reason' });
        return;
      }
      if (errors.homeCare) {
        handleDisplayFlashMessage('Please fill in Home Care description', 'error');
        this.setState({ errors, currentTab: 'homeCare' });
        return;
      }
    }

    this.setState({ isLoading: true });

    try {
      await this.saveSoapNote();
      this.setState({
        isLoading: false,
      }, () => {
        onExitCallback(callbackOptions);
        handleDisplayFlashMessage(
          'Chart has been updated successfully',
        );
      });
    } catch (error) {
      if (error.name === 'LockedSoapError') {
        handleDisplayFlashMessage(error.message, 'error');
      } else if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
    }

    removeBeforeInactiveAction('soap');
    handleCloseSOAPNoteDialog();
  }

  // This method is also used to save SOAP before closing staff session due to inactivity
  async saveSoapNote() {
    const { soap, newFiles, savedFiles, currentBodyTags } = this.state;
    const { handleDisplayFlashMessage } = this.props;
    if (!soap.isChangeable) {
      return;
    }

    let newSoap = {};
    try {
      const { business, auth } = this.props;
      const preparedData = prepareSoapData({
        newFiles,
        savedFiles,
        currentBodyTags,
        soap,
        businessId: business.id,
      });
      newSoap = await SoapNotesApi.updateSOAPNote(soap.id, preparedData, auth);
      if (handleDisplayFlashMessage) {
        handleDisplayFlashMessage(
          'The chart saved successfully',
        );
      }
      this.saveUnsavedChanges();
    } catch (error) {
      if (error?.message?.includes('Session is expired') && handleDisplayFlashMessage) {
        handleDisplayFlashMessage('Session is expired. If necessary, copy the text and refresh the page please', 'error')
      } else if (handleDisplayFlashMessage) {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      if (saveTimeoutId) {
        clearTimeout(saveTimeoutId);
      }
    }
    return newSoap;
  }

  handleTabChange = async (event, value) => {
    const { isUnsavedChanges, currentTab, soap } = this.state;
    const { handleDisplayFlashMessage } = this.props;

    if (currentTab === 'reason' && isUnsavedChanges) {
      const { isValid, errors } = validateObjectByMap(soap, saveSoapNoteErrorsMap);
      if (!isValid) {
        this.setState({ errors });
        return;
      }
    }

    try {
      if (!isUnsavedChanges) {
        this.setState({ currentTab: value });
        return;
      }
      await this.saveSoapNote();
      this.setState({ currentTab: value }, this.saveUnsavedChanges());
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired. If necessary, copy the text and refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
    }
  }

  async onClickLockSoapNote(dataUrl) {
    if (!dataUrl) {
      this.setState({
        isSignatureEmpty: true,
      });
      return;
    }

    this.setState({
      isSignatureEmpty: false,
      isSignatureDialogLoading: true,
    });

    const { soap, newFiles, savedFiles, currentBodyTags } = this.state;
    const {
      business,
      auth,
      onExitCallback,
      callbackOptions = {},
      handleDisplayFlashMessage,
      removeBeforeInactiveAction,
      handleCloseSOAPNoteDialog
    } = this.props;

    const preparedData = prepareSoapData({
      newFiles,
      savedFiles,
      currentBodyTags,
      dataUrl,
      soap,
      businessId: business.id,
    });

    try {
      await SoapNotesApi.updateAndLockSOAPNote(soap.id, preparedData, auth);
      if (this.saveUnsavedChanges) {
        this.saveUnsavedChanges()
      }
      onExitCallback(callbackOptions);
      handleDisplayFlashMessage(
        'Chart has been locked successfully',
      );

      removeBeforeInactiveAction('soap');
      handleCloseSOAPNoteDialog();
    } catch (error) {
      console.log('updateAndLockSOAPNote_error', error)
      if (error.name === 'LockedSoapError') {
        handleDisplayFlashMessage(error.message, 'error');
        return
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        return
      }
      handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
    }
  }

  onLoadBodyImage() {
    const { currentBodyTagType: { value }, bodySize } = this.state;
    if (bodySize[value]) {
      return;
    }

    const { width, height } = this.bodyImageRef.current.getBoundingClientRect();
    this.setState({
      bodySize: {
        ...bodySize,
        [value]: {
          width,
          height,
        },
      },
    });
  }

  onClickSetBodyTag(event) {
    const {
      currentBodyTags,
      currentBodyTagType,
      soap: { isChangeable },
    } = this.state;
    if (!isChangeable) {
      return;
    }

    const {
      x: imageLeft,
      y: imageTop,
      width: imageWidth,
      height: imageHeight,
    } = this.bodyImageRef.current.getBoundingClientRect();
    const { clientX, clientY } = event;
    const [x, y] = calculatePoint({
      clientX, clientY, imageLeft, imageTop, imageWidth, imageHeight,
    });
    const tagId = uuid4();

    const notInitialBodyTags = currentBodyTags.filter(({ status }) => {
      return status !== 'initial';
    });
    const notDeletedBodyTags = notInitialBodyTags.filter(({ status }) => {
      return status !== 'deleted';
    });
    const newTag = {
      id: tagId,
      BodyTagTypeId: currentBodyTagType.value,
      number: calculateNextBodyTagsNumber(notDeletedBodyTags),
      pointData: { x, y, imageWidth, imageHeight },
      status: 'initial',
    }

    this.setState({
      isTagEditorOpened: true,
      selectedBodyTagId: tagId,
      currentBodyTags: [...notInitialBodyTags, newTag],
    });
  }

  onClickOpenTagEditor = (id) => () => {
    const { selectedBodyTagId, soap: { isChangeable } } = this.state;
    if (!isChangeable || (selectedBodyTagId === id)) {
      return;
    }

    this.setState({
      isTagEditorOpened: true,
      selectedBodyTagId: id,
    });
  };

  onClickCloseTagEditor() {
    this.setState({
      isTagEditorOpened: false,
      selectedBodyTagId: null,
    });
  }

  onSaveOrRemoveTag = async () => {
    const { handleDisplayFlashMessage } = this.props;
    try {
      await this.saveSoapNote();
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired. Refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      this.addUnsavedChanges()
    }
  }

  onClickSaveTagText = (tagId, title) => () => {
    const { currentBodyTags, soap: { isChangeable } } = this.state;
    if (!isChangeable || !title) {
      return;
    }

    const newBodyTags = currentBodyTags.map((tag) => {
      if (tag.id !== tagId) {
        return tag;
      }

      return {
        ...tag,
        title,
        status: 'new',
      };
    });

    this.setState({
      currentBodyTags: newBodyTags,
      isTagEditorOpened: false,
      selectedBodyTagId: null,
    }, this.onSaveOrRemoveTag);
  };

  onClickRemoveTag = (tagId) => () => {
    const { currentBodyTags, soap: { isChangeable } } = this.state;
    if (!isChangeable) {
      return;
    }

    const newBodyTags = currentBodyTags.map((tag) => {
      if (tag.id !== tagId) {
        return tag;
      }

      return { ...tag, status: 'deleted' };
    });

    this.setState({
      currentBodyTags: newBodyTags,
      isTagEditorOpened: false,
      selectedBodyTagId: null,
    }, this.onSaveOrRemoveTag);
  };

  onChangeFilesField({ target: { files } }) {
    const { handleDisplayFlashMessage } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    if (files > 3 || (this.state.newFiles.length + files.length) > 3) {
      handleDisplayFlashMessage('Only 3 files can be uploaded at once', 'error');
      return;
    }

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const { isValid, message } = validateFile(file);
      if (!isValid) {
        console.log('onChangeFilesField', message);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        return this.setState(({ newFiles }) => ({
          newFiles: [
            {
              id: uuid4(),
              file,
              name: file.name,
              preview: reader.result,
            },
            ...newFiles,
          ],
        }), this.addUnsavedChanges);
      };
      reader.readAsDataURL(file);
    }
  }

  onClickShowFilePreview = (path, extSource) => () => {
    this.setState({
      currentFile: {
        ext: getExtension(extSource) || 'none',
        path,
      },
      isFilePreviewDialogOpened: true,
    });
  };

  onCloseFilePreviewDialog() {
    this.setState({
      currentFile: {},
      isFilePreviewDialogOpened: false,
    });
  }

  onClickRemoveNewFile = (id) => () => {
    const { newFiles: oldFiles } = this.state;
    const newFiles = oldFiles.filter(file => file.id !== id);
    this.setState({ newFiles });
  };

  onChangeNewFile = (id) => ({ target: { value } }) => {
    const { newFiles: oldFiles } = this.state;
    const sourceFile = oldFiles.find(file => file.id === id);

    const updatedFile = {
      ...sourceFile,
      name: value,
    };

    const index = oldFiles.indexOf(sourceFile);
    const newFiles = [
      ...oldFiles.slice(0, index),
      updatedFile,
      ...oldFiles.slice(index + 1),
    ];

    this.setState({ newFiles });
  };

  onChangeSavedFile = (id) => ({ target: { value } }) => {
    const { savedFiles } = this.state;
    const sourceFile = savedFiles.find(file => file.id === id);

    const updatedFile = {
      ...sourceFile,
      name: value,
      changed: true,
    };

    const index = savedFiles.indexOf(sourceFile);
    const newFiles = [
      ...savedFiles.slice(0, index),
      updatedFile,
      ...savedFiles.slice(index + 1),
    ];

    this.setState({ savedFiles: newFiles }, this.addUnsavedChanges);
  };

  onClickRemoveSavedFile = (id) => async () => {
    this.setState({ attachmentLoading: true });
    const { deleteSoapFile, handleDisplayFlashMessage} = this.props;
    const { savedFiles, soap } = this.state;
    const savedFile = savedFiles.find(file => file.id === id);

    try {
      await deleteSoapFile(savedFile.id, soap.id, soap.AppointmentId);

      const index = savedFiles.indexOf(savedFile);
      const updatedSavedFiles = [
        ...savedFiles.slice(0, index),
        ...savedFiles.slice(index + 1),
      ];

      this.setState({
        savedFiles: updatedSavedFiles,
      });
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      console.log('onClickRemoveSavedFile_Error', error);
    }

    this.setState({ attachmentLoading: false });
  };

  onCreateFile = (id) => async (callback) => {
    this.setState({ attachmentLoading: true });

    const { newFiles, savedFiles, soap } = this.state;
    const { business: { id: businessId }, queryId: clientId, createSoapFile, handleDisplayFlashMessage } = this.props;

    const newFile = newFiles.find(file => file.id === id);
    const preparedFile = prepareNewFile(newFile, soap.id, soap.AppointmentId, clientId, businessId);

    try {
      const { payload: { soapFile } } = await createSoapFile(
        preparedFile,
        soap.id,
      );

      const updatedSavedFiles = [
        soapFile,
        ...savedFiles,
      ];

      const index = newFiles.indexOf(newFile);
      const updatedNewFiles = [
        ...newFiles.slice(0, index),
        ...newFiles.slice(index + 1),
      ];

      this.setState({
        savedFiles: updatedSavedFiles,
        newFiles: updatedNewFiles,
      });
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      console.log('onCreateFile_Error', error);
    }

    this.setState({ attachmentLoading: false }, callback);
  };

  onSaveFile = (id) => async (callback) => {
    this.setState({ attachmentLoading: true });

    const { savedFiles, soap } = this.state;
    const { updateSoapFile, handleDisplayFlashMessage } = this.props;
    const savedFile = savedFiles.find(file => file.id === id);
    const preparedFile = prepareSavedFile(savedFile, soap.id, soap.AppointmentId);

    try {
      const { payload: { soapFile } } = await updateSoapFile(
        id,
        preparedFile,
        soap.id,
      );

      const updatedFile = {
        ...soapFile,
        changed: false,
      };

      const index = savedFiles.indexOf(savedFile);
      const updatedSavedFiles = [
        ...savedFiles.slice(0, index),
        updatedFile,
        ...savedFiles.slice(index + 1),
      ];

      this.setState({
        savedFiles: updatedSavedFiles,
      });
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      console.log('onSaveFile_Error', error);
    }

    this.setState({ attachmentLoading: false }, callback);
  };

  onCancelFileChanges = (id) => () => {
    const { savedFiles } = this.state;
    const sourceFile = savedFiles.find(file => file.id === id);

    const updatedFile = {
      ...sourceFile,
      name: sourceFile.originalName,
      changed: false,
    };

    const index = savedFiles.indexOf(sourceFile);
    const newFiles = [
      ...savedFiles.slice(0, index),
      updatedFile,
      ...savedFiles.slice(index + 1),
    ];

    this.setState({ savedFiles: newFiles });
  }

  onClickOpenSignatureDialog() {
    this.setState({ isSignatureDialogOpened: true });
  }

  onClickCloseSignatureDialog() {
    this.setState({ isSignatureDialogOpened: false });
  }

  componentWillUnmount() {
    if (printTimeoutid) {
      clearTimeout(printTimeoutid);
    }
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }
  }

  onClickPrintPdf = async () => {
    const { auth } = this.props;
    const { soap: {id: querySoapId} } = this.state;
    const newWindow = window.open('', '', 'width=1000,height=1000');

    SoapNotesApi.fetchPdf(querySoapId, auth, true)
      .then((response) => {
        newWindow.document.write(response.data);
        newWindow.document.close();
        newWindow.history.replaceState({}, '', '/');

        printTimeoutid = setTimeout(() => {
          newWindow.focus();
          newWindow.print();
        }, 500);
      });
  }

  getNewData = async (appointmentId, withApps) => {
    const {
      auth,
      loadBodyTagTypes,
      loadBodyTags,
      loadSoapFiles,
      loadPrimaryReasons,
      handleDisplayFlashMessage
    } = this.props;
    const {
      sortedClientAppointments
    } = this.state;
    if (!appointmentId) {
      this.setState({isLoading: false})
      return handleDisplayFlashMessage('Client Appointments not found', 'error');
    }
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }
    try {
      if (!withApps) {
        this.setState({isLoading: true})
      }
      const { soap, bodyTagTypes, currentBodyTags, soapFiles } = await SoapNotesApi.fetchSOAPNoteByAppointment(
        appointmentId,
        auth,
      );
      if (!soap?.id) {
        this.setState({isLoading: false})
        return handleDisplayFlashMessage('SOAP Note not found', 'error');
      }
      loadSoapFiles(soap.id);
      loadBodyTags(soap.id);
      const bodyTagTypesOptions = bodyTagTypes.map(makeOption);
      const currentBodyTagType = bodyTagTypesOptions.find(({ label }) => {
        return label === 'Front';
      });
      const { subjective, objective, assessment, plan, homeCare, clientId } = soap;
      let sortedAppointments = sortedClientAppointments
      if (withApps) {
        loadPrimaryReasons();
        loadBodyTagTypes()
        const clientAppointments = await SoapNotesApi.fetchClientAppointments(clientId, auth) || [];
        sortedAppointments = sortBy(clientAppointments, 'startTime')
      }
      const currentAppointment = sortedAppointments?.find(({ id }) => id === appointmentId) || {};
      const leftArrowDisabled = currentAppointment?.id === sortedAppointments[0]?.id;
      const rightArrowDisabled = currentAppointment?.id === sortedAppointments[sortedAppointments.length - 1]?.id;
      const currentIndex = (sortedAppointments?.findIndex(({ id }) => id === currentAppointment?.id) || 0) + 1;

      return this.setState({
        errors: {},
        currentBodyTags,
        currentBodyTagType,
        sortedClientAppointments: sortedAppointments,
        currentAppointment,
        leftArrowDisabled,
        rightArrowDisabled,
        currentIndex,
        soap: {
          ...soap,
          subjective: initializeEditorState(subjective),
          objective: initializeEditorState(objective),
          assessment: initializeEditorState(assessment),
          plan: initializeEditorState(plan),
          homeCare: initializeEditorState(homeCare),
        },
        savedFiles: soapFiles,
        pdfUrl: `/soap-notes/${soap.id}/pdf`,
        isLoading: false,
      });
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        this.setState({isLoading: false})
        return handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        this.setState({isLoading: false})
        return handleDisplayFlashMessage(err?.message || 'Unexpected error, please try again', 'error');
      }
    }
  }

  goToBack = async () => {
    const { sortedClientAppointments, currentAppointment, rightArrowDisabled, isUnsavedChanges } = this.state;
    const {selectedAppointmentId} = this.props;
    if (rightArrowDisabled) {
      this.setState({rightArrowDisabled: false})
    }
    if (isUnsavedChanges) {
      await this.saveSoapNote();
      this.saveUnsavedChanges();
    }
    const currentIndex = sortedClientAppointments.findIndex(({ id }) => id === currentAppointment.id);
    const newIndex = currentIndex - 1;
    const newAppointment = sortedClientAppointments[newIndex];
    this.getNewData(newAppointment?.id || selectedAppointmentId)
  }

  goToNext = async () => {
    const { sortedClientAppointments, currentAppointment, leftArrowDisabled, isUnsavedChanges } = this.state;
    if (leftArrowDisabled) {
      this.setState({leftArrowDisabled: false})
    }
    if (isUnsavedChanges) {
      await this.saveSoapNote();
      this.saveUnsavedChanges();
    }
    const {selectedAppointmentId} = this.props;
    const currentIndex = sortedClientAppointments.findIndex(({ id }) => id === currentAppointment.id);
    const newIndex = currentIndex + 1;
    const newAppointment = sortedClientAppointments[newIndex];
    this.getNewData(newAppointment?.id || selectedAppointmentId)
  }

  render() {
    const {
      currentTab,
      soap,
      isFilePreviewDialogOpened,
      currentFile,
      savedFiles,
      isLoading,
      isSignatureDialogOpened,
      pdfUrl,
      newFiles,
      isSignatureDialogLoading,
      isSignatureEmpty,
      isDeleteDialogOpened,
      isUnlockDialogOpened,
      currentAppointment,
      sortedClientAppointments,
      rightArrowDisabled,
      leftArrowDisabled,
      currentIndex
    } = this.state;
    const {
      classes,
      handleCloseSOAPNoteDialog,
      primaryReasons,
      mainStyle,
      ...restProps
    } = this.props;
    const primaryReasonsOptions = prepareOptions(primaryReasons.byId);
    const currentPrimaryReason = primaryReasonsOptions.find(
      ({ value }) => (value === soap.PrimaryReasonId)
    );

    const TabComponent = tabComponents[currentTab];
    const allTimes = sortedClientAppointments?.filter(({ startTime }) => moment.tz(startTime, this.props?.business?.timezone)?.format('YYYY-MM-DD') === moment.tz(currentAppointment?.startTime, this.props?.business?.timezone)?.format('YYYY-MM-DD'));
    const format = allTimes?.length > 1 ? 'MMM DD, YYYY @ hh:mm A' : 'MMM DD, YYYY';

    return (
      <div className={classes.soapWrapper}>
        <div
          className={classes.headerWrapper}
        >
          <div className={classes.titleWrapper}><div className={classes.headerTitle}>Chart for {soap.clientName}</div></div>
          <div className={classes.dateNavigatorWrapper}>
            <div
              onClick={sortedClientAppointments?.length > 1 && !leftArrowDisabled ? this.goToBack : null}
              className={cx(classes.arrowBox, (sortedClientAppointments?.length <= 1 || leftArrowDisabled) && classes.disabledArrowBox)}
            >
              <ArrowForwardIcon
                data-testid="chartBackButton"
                className={cx(
                  classes.arrowIcon,
                  classes.leftArrowIcon,
                )}
              />
            </div>
            <div className={classes.dateNavigatorContent}>
              {currentAppointment?.startTime && (
                <Typography className={classes.dateNavigatorTitle}>
                  {moment.tz(currentAppointment?.startTime, this.props?.business?.timezone).format(format)}
                </Typography>
              )}
            </div>
            <div
              onClick={sortedClientAppointments?.length > 1 && !rightArrowDisabled ? this.goToNext : null}
              className={cx(classes.arrowBox, (sortedClientAppointments?.length <= 1 || rightArrowDisabled) && classes.disabledArrowBox)}
            >
              <ArrowForwardIcon data-testid="chartNextButton" className={classes.arrowIcon} />
            </div>
          </div>
          <HeaderExtraButtons
            {...this.props}
            pdfUrl={pdfUrl}
            onClickPrintPdf={this.onClickPrintPdf}
            isLocked={soap.isLocked}
            isChangeable={soap.isChangeable}
            handleValidateSoap={this.handleValidateSoap}
            handleOpenDeleteDialog={this.handleOpenDeleteDialog}
            handleOpenUnlockDialog={this.handleOpenUnlockDialog}
            onClose={soap.isChangeable ? this.onClickSaveSoapNote : handleCloseSOAPNoteDialog}
          />
        </div>

        <CustomDialogContent className={classes.main} contentStyle={mainStyle}>
          {soap.isAppointmentCancelled && (
            <OrangeInfoStrip className={classes.noChangeReasonBox}>
              <Typography variant="subtitle1">
                The Chart cannot be changed because its appointment is cancelled
              </Typography>
            </OrangeInfoStrip>
          )}
          <div className={classes.container}>
            <Grid container className={classes.leftContainer}>
              <HumanBody
                {...this.state}
                {...restProps}
                bodyImageRef={this.bodyImageRef}
                handleSelectBodySide={this.handleSelectBodySide}
                onLoadBodyImage={this.onLoadBodyImage}
                onClickSetBodyTag={this.onClickSetBodyTag}
                onClickOpenTagEditor={this.onClickOpenTagEditor}
                onClickCloseTagEditor={this.onClickCloseTagEditor}
                onClickSaveTagText={this.onClickSaveTagText}
                onClickRemoveTag={this.onClickRemoveTag}
                classes={{
                  tag: classes.tag,
                  tagNumber: classes.tagNumber,
                }}
              />
              <div className={classes.bodyTagTitle}>Body Tags (click on the muscle to create a tag)</div>
            </Grid>

            <Grid container className={classes.rightContainer}>
              <Hidden mdDown>
                <Tabs
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                    flexContainer: classes.flexContainer,
                  }}
                  value={currentTab}
                  onChange={this.handleTabChange}
                >
                  {tabs.map((tab) => (
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                      label={tab.label}
                      value={tab.value}
                      disabled={tab.disabled}
                      key={tab.value}
                    />
                  ))}
                </Tabs>
              </Hidden>
              <Hidden mdUp>
                {/* <p className={classes.contentTitle}>Select table</p> */}
                <SelectTabWithSwipe
                  handleTabChange={this.handleTabChange}
                  tabs={mobileTabs}
                  currentTab={currentTab}
                />
                {/* <div>
                  <Select
                    id="SelectTabId"
                    label="Select table"
                    disabled={!soap.isChangeable}
                    onChange={({value}) => this.handleTabChange(null, value)}
                    className={classes.dropDownSelect}
                    options={tabs}
                    value={currentTabOption}
                  />
                </div> */}
              </Hidden>

              <Grid container className={classes.content}>
                <TabComponent
                  {...this.state}
                  {...this.props}
                  newFiles={newFiles}
                  savedFiles={savedFiles}
                  primaryReasonsOptions={primaryReasonsOptions}
                  currentPrimaryReason={currentPrimaryReason}
                  handleChangeEditor={this.handleChangeEditor}
                  handleSelectOption={this.handleSelectOption}
                  onClickShowFilePreview={this.onClickShowFilePreview}
                  onClickRemoveNewFile={this.onClickRemoveNewFile}
                  onChangeNewFile={this.onChangeNewFile}
                  onCreateFile={this.onCreateFile}
                  onClickRemoveSavedFile={this.onClickRemoveSavedFile}
                  onChangeSavedFile={this.onChangeSavedFile}
                  onCancelFileChanges={this.onCancelFileChanges}
                  onSaveFile={this.onSaveFile}
                  onChangeFilesField={this.onChangeFilesField}
                />
              </Grid>

              <Grid item className={classes.bodyTagsBox}>
                <BodyTags
                  {...this.state}
                  saveSoapNote={this.saveSoapNote}
                  classes={{
                    contentTitle: classes.bodyParentTagTitle,
                    tagNumber: classes.tagNumber,
                  }}
                />
              </Grid>
              {/* <GreenButton
                variant="contained"
                size="small"
                className={classes.btn}
                onClick={this.props.saveSoapNote}
                disabled={!this.props.isUnsavedChanges || !soap?.isChangeable}
              >
                Save
              </GreenButton> */}
            </Grid>
          </div>
          <div className={classes.currentIndex}>{currentIndex} of {sortedClientAppointments?.length}</div>
        </CustomDialogContent>

        <CustomDialog
          maxWidth="sm"
          open={isSignatureDialogOpened}
        >
          <SignatureDialog
            {...this.state}
            {...restProps}
            description="By signing this document you are ensuring that it is accurate and complete. After signing the record will be locked from further change"
            isLoading={isSignatureDialogLoading}
            isSignatureEmpty={isSignatureEmpty}
            onClose={this.onClickCloseSignatureDialog}
            onSubmit={this.onClickLockSoapNote}
          />
        </CustomDialog>

        <Dialog
          // maxWidth="md"
          scroll="body"
          open={isFilePreviewDialogOpened}
          onClose={this.onCloseFilePreviewDialog}
          classes={{
            paper: classes.filePreviewPaper,
          }}
        >
          <PreviewFile
            file={currentFile}
            onClose={this.onCloseFilePreviewDialog}
          />
        </Dialog>

        <CustomDialog
          fullWidth
          maxWidth="sm"
          open={isDeleteDialogOpened}
        >
          <DeleteSoapNote
            onClose={this.handleCloseDeleteDialog}
            onSubmit={this.handleDeleteSOAP}
          />
        </CustomDialog>

        <ConfirmDialog
          title="Are you sure you want to unlock the Chart?"
          open={isUnlockDialogOpened}
          onClose={this.handleCloseUnlockDialog}
          onCancel={this.handleCloseUnlockDialog}
          onSuccess={this.handleUnlockSOAP}
        />

        <CircularProgressWithBackdrop loading={isLoading} />
      </div>
    );
  }
}

const mapStateToProps = ({
  bodyTagTypes,
  primaryReasons,
  auth,
  business,
}) => ({
  bodyTagTypes,
  primaryReasons,
  auth,
  business,
});

const mapDispatchToProps = dispatch => ({
  loadBodyTagTypes: bindActionCreators(loadBodyTagTypes, dispatch),
  loadPrimaryReasons: bindActionCreators(loadPrimaryReasons, dispatch),
  loadBodyTags: bindActionCreators(loadBodyTags, dispatch),
  loadSoapFiles: bindActionCreators(loadSoapFiles, dispatch),
  createSoapFile: bindActionCreators(createSoapFile, dispatch),
  updateSoapFile: bindActionCreators(updateSoapFile, dispatch),
  deleteSoapFile: bindActionCreators(deleteSoapFile, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(SoapNote, styles));
