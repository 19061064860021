/*
Duplicated file server/follow-up-time-periods.js
*/

export default {
  day: {
    name: 'Day',
    period: 'day',
  },
  week: {
    name: 'Week',
    period: 'week',
  },
};
