import { Fragment, useState } from 'react';
import {
  FormControl,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../shared_components/CustomDialog';
import Select from './Select';
import { prepareOptions, makeOption } from '../../../shared_client_utils/formUtils';
import GreyButton from '../../../shared_components/buttons/Grey';
import RedButton from '../../../shared_components/buttons/Red';

const useStyles = makeStyles()(theme => ({
  inputBox: {
    width: '100%',
  },
  select: {
    width: theme.spacing(35),
  },
  buttonBox: {
    justifyContent: 'space-between',
  },
}));

const AppointmentCancellation = (props) => {
  const { onClose } = props;
  const {classes} = useStyles();

  const [cancellationReasons, setCancellationReasons] = useState(() => {
    return prepareOptions(props.cancellationReasons.byId).map(makeOption);
  });
  const [selectedReason, setSelectedReason] = useState(null);

  const handleCancelAppointment = () => {
    let reasonId = '';
    let reasonLabel = '';
    if (selectedReason) {
      reasonId = selectedReason.value;
      reasonLabel = selectedReason.label;
    }

    props.handleCancelAppointment(reasonId, reasonLabel);
  }

  return (
    <Fragment>
      <CustomDialogTitle onClose={onClose}>
        Cancel appointment?
      </CustomDialogTitle>

      <CustomDialogContent>
        <FormControl className={classes.inputBox}>
          <Select
            id="cancellationReasonId"
            label="Cancellation reason"
            onChange={(option) => setSelectedReason(option)}
            options={cancellationReasons}
            value={selectedReason}
            className={classes.select}
          />
        </FormControl>
      </CustomDialogContent>

      <CustomDialogActions className={classes.buttonBox}>
        <RedButton
          variant="contained"
          size="small"
          onClick={handleCancelAppointment}
        >
          Yes
        </RedButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={onClose}
        >
          No
        </GreyButton>
      </CustomDialogActions>
    </Fragment>
  );
};

export default AppointmentCancellation;
