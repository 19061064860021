export const tagRadius = 12.5;

export const calculateNextBodyTagsNumber = (tags) => {
  if (tags.length === 0) {
    return 1;
  }
  const numbers = tags.map(tag => tag.number);
  const lastNumber = Math.max.apply(null, numbers);
  return lastNumber + 1;
};

const calculateCenterByX = (x, width) => {
  if (x < tagRadius) {
    return x;
  } else if (x > (width - tagRadius)) {
    return width - tagRadius * 2;
  } else {
    return x - tagRadius;
  }
};

const calculateCenterByY = (y, height) => {
  if (y < tagRadius) {
    return y;
  } else if (y > (height - tagRadius)) {
    return height - tagRadius * 2;
  } else {
    return y - tagRadius;
  }
};

export const calculatePoint = ({
  clientX, clientY, imageLeft, imageTop, imageWidth, imageHeight,
}) => {
  const clickedX = Math.abs(Math.round(clientX - imageLeft));
  const clickedY = Math.abs(Math.round(clientY - imageTop));
  const x = calculateCenterByX(clickedX, imageWidth);
  const y = calculateCenterByY(clickedY, imageHeight)

  return [x, y];
};
