import { useState } from 'react';
import {
  Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';
import OutlinedInput from '../../../../shared_components/Input';
import GreyButton from '../../../../shared_components/buttons/Grey';
import RedButton from '../../../../shared_components/buttons/Red';

const useStyles = makeStyles()(theme => ({
  inputBox: {
    width: '100%',
  },
  info: {
    fontWeight: 500,
  },
  confirmBox: {
    flexDirection: 'column',
    marginTop: theme.spacing(),
  },
  confirmInput: {
    marginTop: theme.spacing(),
  },
  error: {
    color: theme.palette.error.main,
  },
  buttonBox: {
    justifyContent: 'space-between',
  },
}));

const DeleteSoapNote = (props) => {
  const { onClose, onSubmit } = props;
  const {classes} = useStyles();

  const [confirmInput, setConfirmInput] = useState('');
  const [isConfirmInputError, setConfirmInputError] = useState(false);

  const handleConfirmDeletion = () => {
    if (confirmInput.toLowerCase() !== 'delete') {
      setConfirmInputError(true);
      return;
    }
    setConfirmInputError(false);

    return onSubmit();
  };

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        Are you sure you want to delete the Chart?
      </CustomDialogTitle>

      <CustomDialogContent>
        <Grid container className={classes.info}>
          Be careful&nbsp;&mdash;&nbsp;this action cannot be undone!
        </Grid>
        <Grid container className={classes.confirmBox}>
          <Grid
            item
            className={isConfirmInputError ? classes.error : ''}
          >
            Please type DELETE word (case insensitive).
          </Grid>
          <Grid item>
            <OutlinedInput
              id="confirmInput"
              name="confirmInput"
              value={confirmInput}
              onChange={({ target: { value }}) => setConfirmInput(value)}
              className={classes.confirmInput}
              error={isConfirmInputError}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions className={classes.buttonBox}>
        <RedButton
          variant="contained"
          size="small"
          onClick={handleConfirmDeletion}
        >
          Yes
        </RedButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={onClose}
        >
          No
        </GreyButton>
      </CustomDialogActions>
    </>
  );
};

export default DeleteSoapNote;
