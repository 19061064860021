import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import OutlinedInput from '../../../../shared_components/Input';
import DoneButton from '../../../../shared_components/buttons/Done';
import CloseButton from '../../../../shared_components/buttons/Close';

const useStyles = makeStyles()((theme) => ({
  buttonBox: {
    width: 'auto',
    marginLeft: theme.spacing(3/2),
  },
  cancelButton: {
    marginLeft: theme.spacing(1/2),
  },
  cancelIcon: {
    height: theme.spacing(9/4),
    width: theme.spacing(9/4),
  },
}));

const InputWithButtons = (props) => {
  const {
    component,
    displaySave,
    displayCancel,
    onSaveButtonProps,
    onCancelButtonProps,
    ...restProps
  } = props;
  const {classes} = useStyles();

  const Input = component || OutlinedInput;

  return (
    <Grid container>
      <Input {...restProps} />

      {(displaySave || displayCancel) && (
        <Grid container className={classes.buttonBox}>
          {displaySave && <DoneButton {...onSaveButtonProps} />}
          {displayCancel && (
            <CloseButton
              {...onCancelButtonProps}
              className={classes.cancelButton}
              classes={{
                icon: classes.cancelIcon,
              }}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default InputWithButtons;
