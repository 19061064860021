/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
// import { useSpring, animated } from 'react-spring';
// import SwipeIcon from '@mui/icons-material/Swipe';
import {
  HighlightOffRounded as HighlightOffRoundedIcon,
} from '@mui/icons-material';

// import Picker from "react-scrollable-picker";

import { makeStyles } from 'tss-react/mui';
// import Select from '../Select/TextField';
// import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';

const useStyles = makeStyles()(() => ({
  dropDownSelect: {
    width: '275px',
    '& input': {
      color: '#ed6c01'
    }
  },
  tabRoot: {
    position: 'relative',
    zIndex: 1,
    padding: '10px 0',
    backgroundColor: "rgb(254,233,197, .2)",
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '45px',
  },
  iconWrap: {
    marginRight: '3px',
    opacity: 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  pickerWrapper: {
    position: 'absolute',
    width: '100%',
    marginTop: '-.5rem',
  },

  activeTab: {
    width: '100%',
  },
  activeTabLabel: {
    fontSize: '1.2em',
    fontWeight: '500',
    padding: '5px 10px',
    color: '#ed6c01',
    borderRadius: '5px',
    // background: 'linear-gradient(to top, #ed9301, #fcfcfc 50%, #fcfcfc 70%, #ed9301)',
    backgroundColor: '#e0e0e0',
    width: '100%',
    textAlign: 'center',
  },
  picker: {
    width: '100%',
    // background: 'linear-gradient(to top, #ed9301, #edae01 15%, #fcfcfc 40%, #fcfcfc 60%, #edae01 85%, #ed9301)',
    background: 'linear-gradient(to top, #e0e0e0, #e0e0e0 15%, #fcfcfc 40%, #fcfcfc 60%, #e0e0e0 85%, #e0e0e0)',
    borderRadius: '.5rem',
    '& .picker-container': {
      '& .picker-inner': {
        '& .picker-column': {
          '& .picker-scroller': {
            '& .picker-item': {
              color: 'black',
              fontWeight: '500'
            },
            '& .picker-item.picker-item-selected': {
              color: '#ed6c01',
              fontWeight: '500'
            },
          },
        },
        '& .picker-highlight': {
        },

        '& .picker-highlight::before': {
          backgroundColor: 'black'
        },
        '& .picker-highlight::after': {
          backgroundColor: 'black'
        },
      },
    },
  },
  cancelIcon: {
    position: 'absolute',
    right: '0',
    top: '-2rem',
    height: '2rem',
    width: 'auto',
  },
  pickerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
}));

const SelectBodyWithSwipe = (props) => {
  const {
    handleTabChange,
    tabs = [],
    currentTab,
  } = props;
  const {classes} = useStyles();
  // const [swipeDirection, setSwipeDirection] = useState('');

  const currentTabOption = tabs.find(
    ({ value }) => (value === currentTab?.value)
  );

  const findNextValue = (tabValue, direction) => {
    const currentIndex = tabs.findIndex(({value}) => value === tabValue);
    let newIndex;
    if (direction === 'right') {
      newIndex = (currentIndex === 0) ? tabs.length - 1 : currentIndex - 1;
    } else if (direction === 'left') {
      newIndex = (currentIndex === tabs.length - 1) ? 0 : currentIndex + 1;
    }
    return tabs[newIndex];
  };

  // const vibrate = (time = 200) => {
  //   if (window?.navigator?.vibrate) {
  //     window.navigator.vibrate(time);
  //   }
  // }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // setSwipeDirection('right')
      const prev = findNextValue(currentTab.value, 'left');
      handleTabChange(prev)
      // vibrate();
      // setTimeout(() => {
      //   handleTabChange(prev)
      //   setSwipeDirection('')
      // }, 500)
    },
    onSwipedRight: () => {
      // setSwipeDirection('left')
      const next = findNextValue(currentTab.value, 'right');
      handleTabChange(next)
      // vibrate();
      // setTimeout(() => {
      //   handleTabChange(next)
      //   setSwipeDirection('')
      // }, 500)
    },
  });

  // const springProps = useSpring({
  //   transform: swipeDirection === 'left'
  //     ? 'translate3d(-50%,0,0)'
  //     : swipeDirection === 'right'
  //       ? 'translate3d(40%,0,0)'
  //       : 'translate3d(0,0,0)',
  //   config: { tension: 300, friction: 30 }
  // });

  // const springProps = useSpring({
  //   from: { marginRight: '3px' },
  //   to: {
  //     marginRight:
  //       swipeDirection === 'left' ? '5rem' : swipeDirection === 'right' ? '5rem' : '3px',
  //   },
  //   config: { tension: 500, friction: 30 }
  // });

  const optionGroups = {
    title: tabs.map((i) => ({ value: i.label, label: i.label }))
  };

  const [isPickerShow, setIsPickerShow] = useState(false);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const onShowPicker = () => {
    setIsPickerShow(true)
    setIsScrollDisabled(true);
  }
  const onClosePicker = () => {
    setIsPickerShow(false)
    setIsScrollDisabled(false);
  }

  const handleChange = (name, value) => {
    const item = tabs.find(({ label }) => label === value);
    handleTabChange(item);
    onClosePicker()
  };

  useEffect(() => {
    const preventScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    if (isScrollDisabled) {
      window.addEventListener('scroll', preventScroll, { passive: false });
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });
    } else {
      window.removeEventListener('scroll', preventScroll, { passive: false });
      window.removeEventListener('wheel', preventScroll, { passive: false });
      window.removeEventListener('touchmove', preventScroll, { passive: false });
    }

    return () => {
      window.removeEventListener('scroll', preventScroll, { passive: false });
      window.removeEventListener('wheel', preventScroll, { passive: false });
      window.removeEventListener('touchmove', preventScroll, { passive: false });
    };
  }, [isScrollDisabled]);

  const [Picker, setPicker] = useState(null);

  useEffect(() => {
    import('react-scrollable-picker').then(module => {
      setPicker(() => module.default);
    }).catch(err => {
      console.error('Failed to load react-scrollable-picker:', err);
    });
  }, []);

  if (!Picker) {
    return <div>Loading...</div>;
  }

  return (
    <div {...handlers} className={classes.tabRoot}>
      {/* <animated.div style={springProps}> */}
      <div className={classes.pickerContainer}>
        {/* <Select
          blurOnSelect
          id="BodySideId"
          onChange={handleTabChange}
          className={classes.dropDownSelect}
          options={tabs}
          value={currentTabOption}
          inputMode="none"
        /> */}
        <div className={classes.pickerWrapper}>
          {isPickerShow ? (
            <div className={classes.picker}>
              <HighlightOffRoundedIcon
                className={classes.cancelIcon}
                onClick={onClosePicker}
              />
              <Picker
                optionGroups={optionGroups}
                valueGroups={{ title: currentTabOption?.label }}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className={classes.activeTabLabel} onClick={onShowPicker}>{currentTabOption?.label}</div>
          )}
        </div>
      </div>
      {/* </animated.div> */}
      {/* <animated.div style={springProps}>
        <span className={classes.iconWrap}><SwipeIcon /></span>
      </animated.div> */}
    </div>
  );
};

export default SelectBodyWithSwipe;
