import BaseIcon from './BaseIcon';
import { PrinterIcon } from '../icons';

const PrinterButton = (props) => (
  <BaseIcon
    {...props}
    component={PrinterIcon}
    iconProps={{
      color: 'primary',
    }}
  />
);

export default PrinterButton;
