import { useRef, Fragment } from 'react';
import { Grid, DialogContentText, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { MouseIcon } from '../../../shared_components/icons';
import ESignature from './ESignature';
import OrangeButton from '../../../shared_components/buttons/Orange';
import GreyButton from '../../../shared_components/buttons/Grey';
import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../shared_components/CustomDialog';
import CircularProgressWithBackdrop from '../../../shared_components/CircularProgressWithBackdrop';

const useStyles = makeStyles()((theme) => ({
  heading: {
    fontSize: '25px',
    textAlign: 'center',
    fontWeight: 'normal',
    marginRight: '15px',
  },
  mouseIconBox: {
    width: theme.spacing(11/4),
  },
  mouseIcon: {
    width: 'auto',
    height: theme.spacing(7/2),
    transform: 'rotate(-33deg)',
  },
  description: {
    fontSize: '16px',
    color: '#ACACAC',
    textAlign: 'center',
    marginBottom: '10px',
  },
  submitBtnBox: {
    justifyContent: 'space-between',
  },
  signLine: {
    position: 'relative',
  },
  soapCanvas: {
    boxShadow: 'none',
    cursor: 'crosshair',
    touchAction: 'none',
    width: '100%',
  },
}));

const SignatureDialog = (props) => {
  const {
    title,
    description,
    onClose,
    onSubmit,
    isLoading,
    isSignatureEmpty,
    eSignatureProps,
  } = props;

  const {classes} = useStyles();

  const signatureRef = useRef(null);

  const handleSubmitSignature = () => {
    const dataUrl = signatureRef.current.getDataUrl();
    return onSubmit(dataUrl);
  }

  const handleClearSignature = () => signatureRef.current.clearCanvas();

  return (
    <Fragment>
      <CustomDialogTitle onClose={onClose} >
        <Grid container>
          <Grid item className={classes.heading}>{title}</Grid>
          <Grid item className={classes.mouseIconBox}>
            <MouseIcon color="primary" className={classes.mouseIcon} />
          </Grid>
        </Grid>
      </CustomDialogTitle>

      <CustomDialogContent>
        {description && (
          <DialogContentText className={classes.description}>
            {description}
          </DialogContentText>
        )}
        <div className={classes.signLine}>
          <ESignature
            width="540"
            height="160"
            {...eSignatureProps}
            ref={signatureRef}
            classes={{
              canvas: classes.soapCanvas,
            }}
          />
          {isSignatureEmpty && (
            <FormHelperText error={isSignatureEmpty}>
              You must sign!
            </FormHelperText>
          )}
        </div>
      </CustomDialogContent>

      <CustomDialogActions className={classes.submitBtnBox}>
        <GreyButton
          variant="text"
          size="medium"
          onClick={handleClearSignature}
        >
          Clear
        </GreyButton>
        <OrangeButton
          variant="contained"
          size="medium"
          onClick={handleSubmitSignature}
        >
          Submit
        </OrangeButton>
      </CustomDialogActions>

      <CircularProgressWithBackdrop loading={isLoading} />
    </Fragment>
  );
};

SignatureDialog.defaultProps = {
  title: 'Sign below',
  isLoading: false,
  isSignatureEmpty: false,
  eSignatureProps: {},
};

export default SignatureDialog;
